<template>
  <div class="sidebar-left">
    <div class="sidebar">

      <!-- Logged In User Profile Sidebar -->
      <user-profile-sidebar
        :shall-show-user-profile-sidebar="shallShowUserProfileSidebar"
        :profile-user-data="profileUserData"
        @close-sidebar="$emit('update:shall-show-user-profile-sidebar', false)"
      />

      <!-- Sidebar Content -->
      <div
        class="sidebar-content"
        :class="{'show': mqShallShowLeftSidebar}"
      >

        <!-- Sidebar close icon -->
        <span class="sidebar-close-icon">
          <feather-icon
            icon="XIcon"
            size="16"
            @click="$emit('update:mq-shall-show-left-sidebar', false)"
          />
        </span>

        <!-- Header -->
        <div class="chat-fixed-search">
          <div class="d-flex align-items-center w-100">
            <!-- <div class="sidebar-profile-toggle">
               <b-avatar badge badge-variant="danger" :text="avatarText(profileUserMinimalData.fullName)" class="badge-minimal" />
            </div> -->
            <!-- Search -->
            <h2 class="mb-0"><strong>List Chat</strong></h2>
            <!-- <b-input-group class="input-group-merge ml-1 w-100 round">
              <b-input-group-prepend is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search..."
              />
            </b-input-group> -->
          </div>
        </div>

        <!-- ScrollArea: Chat & Contacts -->
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="chat-user-list-wrapper list-group scroll-area"
        >

         
          <!-- Contacts Title -->
          <h4 class="chat-list-title">
            Unassigned
          </h4>

          <!-- Contacts -->
          <ul class="chat-users-list contact-list media-list"  v-for="cm in chatUnmap" :key="cm">
            <li v-if="cm.status === 'Unassigned' || cm.status === 'Returned by Expert'" @click="$emit('open-chat', cm.id)" :class="{'active': activeChat.chat_id === cm.id}">
              <template >
                <b-avatar :text="avatarText(cm.userId)" class="badge-minimal" />
                <div class="chat-info flex-grow-1">
                  <b-badge
                  v-if="cm.unseenMsgs"
                  pill
                  variant="primary"
                  class="float-right"
                >
                  {{ cm.unseenMsgs }}
                </b-badge>
                  <h5 class="mb-0">
                  {{cm.userId}}
                  </h5>
                  
                  <p class="card-text text-truncate">
                  {{cm.pesanTerakhir}}
                  </p>
                  
                </div>
                
              </template>
            </li>
          </ul>

          <!-- Contacts Title -->
          <h4 class="chat-list-title">
            Answered by Expert
          </h4>

          <!-- Contacts -->
          <ul class="chat-users-list contact-list media-list"  v-for="cm in chatUnmap" :key="cm">
            <li v-if="cm.status === 'Answered by Expert'" @click="$emit('open-chat', cm.id)" :class="{'active': activeChat.chat_id === cm.id}">
              <template >
                <b-avatar :text="avatarText(cm.userId)" class="badge-minimal" />
                <div class="chat-info flex-grow-1">
                  <b-badge
                  v-if="cm.unseenMsgs"
                  pill
                  variant="primary"
                  class="float-right"
                >
                  {{ cm.unseenMsgs }}
                </b-badge>
                  <h5 class="mb-0">
                  {{cm.userId}}
                  </h5>
                  
                  <p class="card-text text-truncate">
                  {{cm.pesanTerakhir}}
                  </p>
                  
                </div>
                
              </template>
            </li>
          </ul>

          <h4 class="chat-list-title">
            Assigned
          </h4>

          <!-- Chats -->
          <ul class="chat-users-list chat-list media-list" v-for="cm in chatUnmap"  :key="cm">
            
           <li v-if="cm.status === 'Assigned'" @click="$emit('open-chat', cm.id)"  :class="{'active': activeChat.chat_id === cm.id}">
              <template >
                <b-avatar :text="avatarText(cm.userId)" class="badge-minimal" />
                <div class="chat-info flex-grow-1">
                  <h5 class="mb-0">
                  {{cm.userId}}
                  </h5>
                  <p class="card-text text-truncate">
                  {{cm.pesanTerakhir}}
                  </p>
                </div>
              </template>
            </li>
          </ul>

          <!-- Chats Title -->
          <h4 class="chat-list-title">
            Answered
          </h4>

          <!-- Chats -->
          <ul class="chat-users-list chat-list media-list" v-for="cm in chatUnmap"  :key="cm">
            
           <li v-if="cm.status === 'Answered' || cm.status === 'Resolved'" @click="$emit('open-chat', cm.id)"  :class="{'active': activeChat.chat_id === cm.id}">
              <template >
                <b-avatar :text="avatarText(cm.userId)" class="badge-minimal" />
                <div class="chat-info flex-grow-1">
                  <h5 class="mb-0">
                  {{cm.userId}}
                  </h5>
                  <p class="card-text text-truncate">
                  {{cm.pesanTerakhir}}
                  </p>
                </div>
              </template>
            </li>
          </ul>
        </vue-perfect-scrollbar>
      </div>

    </div>
  </div>
</template>

<script>
import {
  BAvatar, BInputGroup, BInputGroupPrepend, BFormInput,BBadge
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { ref, computed } from '@vue/composition-api'
import ChatContact from './ChatContact.vue'
import UserProfileSidebar from './UserProfileSidebar.vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {

    // BSV
    BBadge,
    BAvatar,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,

    // 3rd party
    VuePerfectScrollbar,

    // SFC
    ChatContact,
    UserProfileSidebar,
  },
  props: {
    activeChat:{
      type: Object,
      required : true
    },
    chatUnmap: {
      type: Array,
      required: true,
    },
    chatsContacts: {
      type: Array,
      required: true,
    },
    contacts: {
      type: Array,
      required: true,
    },
    shallShowUserProfileSidebar: {
      type: Boolean,
      required: true,
    },
    profileUserData: {
      type: Object,
      required: true,
    },
    profileUserMinimalData: {
      type: Object,
      required: true,
    },
    activeChatContactId: {
      type: Number,
      default: null,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
    dataAssigned:[],
    dataUnsigned:[]
  },
  setup(props) {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const resolveChatContact = userId => props.chatUnmap.find(chatUnmap => chatUnmap.id === userId)

    // Search Query
    const searchQuery = ref('')

    const searchFilterFunction = chatUnmap => chatUnmap.userId.toLowerCase().includes(searchQuery.value.toLowerCase())
    const filteredChatsContacts = computed(() => props.chatUnmap.filter(searchFilterFunction))
    const filteredContacts = computed(() => props.chatUnmap.filter(searchFilterFunction))

    

    return {
      avatarText,
      // Search Query
      searchQuery,
      filteredChatsContacts,
      filteredContacts,

      // UI
      resolveChatContact,
      perfectScrollbarSettings,
    }
  },
}
</script>
