<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': shallShowUserProfileSidebar || shallShowActiveChatContactSidebar || mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar=shallShowActiveChatContactSidebar=shallShowUserProfileSidebar=false"
    />

    <!-- Main Area -->
    <section class="chat-app-window">
      

      <!-- Start Chat Logo -->
      <div
        v-if="!activeChat.chat"
        class="start-chat-area"
      >
        <div class="mb-1 start-chat-icon">
          <feather-icon
            icon="MessageSquareIcon"
            size="56"
          />
        </div>
        <h4
          class="sidebar-toggle start-chat-text"
          @click="startConversation"
        >
          Start Conversation
        </h4>
      </div>

      <!-- Chat Content -->
      <div
        v-else
        class="active-chat"
      >
      
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">

            <!-- Avatar & Name -->
            <div class="d-flex align-items-center">

              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon
                  icon="MenuIcon"
                  class="cursor-pointer"
                  size="21"
                  @click="mqShallShowLeftSidebar = true"
                />
              </div>

              <b-avatar
                size="36"
                :text="avatarText(activeChat.owner)"
                class="mr-1 cursor-pointer badge-minimal"
                badge
                :badge-variant="resolveAvatarBadgeVariant(activeChat.status)"
              />
              <h6 class="mb-0">
                {{ activeChat.owner }}
              </h6>
            </div>

            <!-- Contact Actions -->
            <div class="d-flex align-items-center">
              <b-button variant="success" @click="showrightbar=true" :disabled="disabledButton">Cek Pertanyaan</b-button>
              <b-button variant="danger" class="ml-2" v-if="activeChat.status !='Resolved'" @click="resolveChat(activeChat.id)">Resolve</b-button>
            </div>
          </header>
        </div>

        <!-- User Chat Area -->
        <vue-perfect-scrollbar
          ref="refChatLogPS"
          :settings="perfectScrollbarSettings"
          class="user-chats scroll-area"
        >
        
          <chat-log
            @getPertanyaan="ambilPertanyaan"
            :checkBoxPertanyaan="checkBoxPertanyaan"
            :chat-data="activeChat"
            :profile-user-avatar="profileUserDataMinimal.avatar"
          />
        </vue-perfect-scrollbar>


        <!-- Message Input -->
        <b-form
          class="chat-app-form"
          @submit.prevent="sendMessage"
        >
        <div class="file btn btn-lg" style="position: relative;overflow: hidden;">
						<i class="fa fa-paperclip"></i>
						<input style="position: absolute; font-size: 50px; opacity: 0; right: 0;top: 0;" type="file" ref="file" name="file" @change="KirimFile(activeChat.chat_id,userData)"/>
				</div>
          <b-input-group class="input-group-merge form-send-message mr-1">
            <b-form-input
              v-model="chatInputMessage"
              placeholder="Enter your message"
            />
          </b-input-group>
          <b-button
            variant="primary"
            type="submit"
          >
            Send
          </b-button>

         
        </b-form>
      </div>
    </section>

    
    <!-- Active Chat Contact Details Sidebar -->
    <chat-active-chat-content-details-sidedbar
      :shall-show-active-chat-contact-sidebar.sync="showrightbar"
      :contact="activeChat.contact || {}"
      :dataPertanyaan="chekedPertanyaan"
      :clusterJawaban="cluster"
      @submitJawaban="ambilJawaban"
      @refreshChat = "refreshChat"
      @closesidebar="tutupsidebar"
      :detailSubmit="detailforSubmit"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <chat-left-sidebar
        :chats-contacts="chatsContacts"
        :contacts="contacts"
        :chatUnmap="chatUnmaped"
        :activeChat="activeChat"
        :shall-show-user-profile-sidebar.sync="shallShowUserProfileSidebar"
        :profile-user-data="profileUserData"
        :profile-user-minimal-data="profileUserDataMinimal"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @show-user-profile="showUserProfileSidebar"
        @open-chat="openChat"

        
      />
    </portal>
  </div>
</template>

<script>
import store from '@/store'
import {
  ref, onUnmounted, nextTick,onMounted
} from '@vue/composition-api'
import {
  BAvatar, BDropdown, BDropdownItem, BForm, BInputGroup, BFormInput, BButton,BIcon
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// import { formatDate } from '@core/utils/filter'
import { $themeBreakpoints } from '@themeConfig'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import ChatLeftSidebar from './ChatLeftSidebar.vue'
import chatStoreModule from './chatStoreModule'
import ChatActiveChatContentDetailsSidedbar from './ChatActiveChatContentDetailsSidedbar.vue'
import ChatLog from './ChatLog.vue'
import useChat from './useChat'
import axios from "axios"
import Pusher from 'pusher-js'
import { avatarText } from '@core/utils/filter'

export default {
  components: {

    // BSV
    BIcon,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,

    // 3rd Party
    VuePerfectScrollbar,

    // SFC
    ChatLeftSidebar,
    ChatActiveChatContentDetailsSidedbar,
    ChatLog,
  },
  data(){
    return{
      showrightbar:false,
      fileAttach:null,
      idchatattach:null,
      url : localStorage.getItem('baseapi')
    }
  },
  methods:{
    tutupsidebar(){
      this.showrightbar = false
    },
    KirimFile(idchat,dataUser){
      this.fileAttach = this.$refs.file.files[0];
      console.log(idchat)
      let formData = new FormData();
      formData.append('attachment', this.fileAttach);
      formData.append('to', idchat);
      formData.append('nama', dataUser.nama);
      axios.post(this.url+'tele/sendMessage',
      formData,
      {
          headers: {
              'Content-Type': 'multipart/form-data',
              xth: dataUser.token 
          }
      }).then(response=>{
        console.log(response)
      })
    }
  },
  setup() {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const CHAT_APP_STORE_MODULE_NAME = 'wa-chat'

    const url = localStorage.getItem('baseapi');

    // Register module
    if (!store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.registerModule(CHAT_APP_STORE_MODULE_NAME, chatStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.unregisterModule(CHAT_APP_STORE_MODULE_NAME)
    })

    const { resolveAvatarBadgeVariant } = useChat()

    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null)
    const scrollToBottomInChatLog = () => {
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
      scrollEl.scrollTop = scrollEl.scrollHeight
    }


    

    const chatUnmaped = ref([])

    // const chatid = ref('chat_id');
    // const pengirim = ref('pengirim');
    // const penerima = ref('penerima');
    // const channel = ref('channel');
    // const pesan = ref('pesan');

    onMounted(() =>{
      Pusher.logToConsole = true;
      const pusher = new Pusher('17d50bafa08cd75d138a',{
        cluster: 'ap1'
      });

      var channel = pusher.subscribe('Telegram-channel');
      channel.bind('Telegram-event', function(data) {
        // chatUnmaped.value.push({
        //   id: data.chat_id,
        //   userId: data.pengirim,
        //   pesanTerakhir: data.pesan,
        // })

        fetchChatAndContacts()
        openChat(activeChat.value.chat_id)
      });

      
    })
    // ------------------------------------------------
    // Chats & Contacts
    // ------------------------------------------------


    
    const chatsContacts = ref([])
    const contacts = ref([])
    


    // const fetchChatAndContacts = () => {
    //   store.dispatch('wa-chat/fetchChatsAndContacts')
    //     .then(response => {
    //       chatsContacts.value = response.data.chatsContacts
    //       console.log(chatsContacts.value)
    //       contacts.value = response.data.contacts
    //       // eslint-disable-next-line no-use-before-define
    //       profileUserDataMinimal.value = response.data.profileUser
    //     })
    // }

    const fetchChatAndContacts = () => {
      axios.get(url+'room?filter=channel,=,Telegram&include=unseen,chat',{
          headers: {
            'xth': userData.token
          }
        })
        .then(response => {
          //console.log(response)

          var chatcon = []

          var dataRoom = response.data.data;
          console.log(dataRoom);

          for(var i= 0; i< dataRoom.length; i++){
          
            var lastPosition = dataRoom[i].chat
            var pesan
            if(lastPosition.length == 0){
              pesan = "Tidak Ada"
            }else{
              pesan = dataRoom[i].chat[lastPosition.length-1].pesan;
            }
            
            
            chatcon.push({
              id: dataRoom[i].chat_id,
              userId: dataRoom[i].owner,
              status: dataRoom[i].status,
              channel: dataRoom[i].channel,
              unseenMsgs: dataRoom[i].unseen,
              pesanTerakhir: pesan,
              chat: dataRoom[i].chat
            })
          }
         
         //console.log(chatcon)

          chatUnmaped.value = chatcon;

          //chatsContacts.value = response.data.chats;

          console.log(chatUnmaped.value);
          const profileUserData = {
            id: userData.iduser,
            avatar: userData.nama,
            fullName: userData.nama,
            status: 'online',
          }

          profileUserDataMinimal.value = profileUserData

          //contacts.value = datachat
          // eslint-disable-next-line no-use-before-define
          

          //console.log(profileUserDataMinimal.value);
        })
    }

    fetchChatAndContacts()

    // ------------------------------------------------
    // Single Chat
    // ------------------------------------------------
    const activeChat = ref({})
    const chatInputMessage = ref('')
    const openChat = idchat => {
      // Reset send message input value
      chatInputMessage.value = ''
      disabledButton.value = true

      axios.get(url+'room?filter=chat_id,=,'+idchat+',channel,=,Telegram&first=ya&include=unseen,chat',{
        headers:{
          'xth': userData.token
        }
      })
        .then(response => {
          activeChat.value = response.data.data
          //this.idchatattach = activeChat.value.chat_id
          console.log(activeChat.value)

          // Set unseenMsgs to 0
          const chat = chatUnmaped.value.find(c => c.id === idchat)
          if (chat) chat.chat.unseenMsgs = 0

          chekedPertanyaan.value = ''
          cluster.value = []

          // Scroll to bottom
          nextTick(() => { scrollToBottomInChatLog() })
        })

      // if SM device =>  Close Chat & Contacts left sidebar
      // eslint-disable-next-line no-use-before-define
      mqShallShowLeftSidebar.value = false
      //nextTick(() => { scrollToBottomInChatLog() })
    }

    const resolveChat = idchat => {
      // Reset send message input value

      axios.put(url+'room/'+idchat,{
        status: 'Resolved',
        },{
          headers:{
            'xth': userData.token
          }
        })
        .then(response => {
          
          console.log(response)
          fetchChatAndContacts()
        })

      // if SM device =>  Close Chat & Contacts left sidebar
      // eslint-disable-next-line no-use-before-define
      mqShallShowLeftSidebar.value = false
      //nextTick(() => { scrollToBottomInChatLog() })
    }
    const sendMessage = () => {
      if (!chatInputMessage.value) return
      const payload = {
        contactId: activeChat.value.owner,
        // eslint-disable-next-line no-use-before-define
        senderId: "Me",
        message: chatInputMessage.value,
        chat_id: activeChat.value.chat_id
      }
      //console.log(activeChat.value)
      axios.post(url+'tele/sendMessage', {
        nama: userData.nama,
        to: payload.chat_id,
        body: payload.message,
        filename: dataFile.value
      })
        .then(response => {
          console.log(activeChat.value)
          activeChat.value.chat.push({
              pengirim: "Me",
              pesan: payload.message
          })

          // Reset send message input value
          chatInputMessage.value = ''

          // Set Last Message for active contact
          //const contact = chatsContacts.value.find(c => c.id === activeChat.value.contact.id)
          //contact.chat.lastMessage = newMessageData

          // Scroll to bottom
          nextTick(() => { scrollToBottomInChatLog() })
        })
    
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // User Profile Sidebar
    // ? Will contain all details of profile user (e.g. settings, about etc.)
    const profileUserData = ref({})
    // ? Will contain id, name and avatar & status
    const profileUserDataMinimal = ref({})

    const shallShowUserProfileSidebar = ref(false)
    const showUserProfileSidebar = () => {
      store.dispatch('wa-chat/getProfileUser')
        .then(response => {
          profileUserData.value = response.data
          shallShowUserProfileSidebar.value = true
        })
    }

    // Active Chat Contact Details
    const shallShowActiveChatContactSidebar = ref(false)

    // UI + SM Devices
    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const startConversation = () => {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true
      }
    }

    var showsidebar = ref(false)

    const onClick = () => {
      showsidebar = true
      console.log(showsidebar)
    }

    //Fungsi Pertanyaan Cluster
    const disabledButton = ref(true)
    const checkBoxPertanyaan = ref([])
    const chekedPertanyaan = ref('')
    const cluster = ref([])
    const detailforSubmit = ref({})
    const dataidPertanyaan = ref([])
    const ambilPertanyaan = data =>{
      if(data.length == 0){
        disabledButton.value = true
      }else{
        disabledButton.value = false
      }
      var dataku =[]
      for(var i = 0;i<data.length;i++){
        dataku.push(data[i].msg)
      }
      chekedPertanyaan.value = dataku.join(' ')
      console.log(dataku)

      dataidPertanyaan.value = data[0].id

      var datachatActive = activeChat.value
        // var datanya = {
        //   chat_id: datachatActive.chat_id,
        //   pertanyaan_id: data[0].id,
        //   penanya: datachatActive.owner,
        //   pertanyaan: chekedPertanyaan.value
        // }
        detailforSubmit.value = {
          chat_id: datachatActive.chat_id,
          pertanyaan_id: data[0].id,
          penanya: datachatActive.owner,
          pertanyaan: chekedPertanyaan.value
        }

      axios.post(url+'cluster_recomendation',{
        pertanyaan: chekedPertanyaan.value
      },
      {
        headers: {
          xth: userData.token
        }
      }
      )
      .then(function (response) {
        console.log(response.data.data);
        cluster.value = response.data.data
        
      })
      .catch(function (error) {
        console.log(error);
      });
    }

    const dataFile= ref()
    const ambilJawaban = data => {
      
      var datachatActive = activeChat.value
      axios.post(url+'pertanyaan_cluster',
      {
        chat_id: datachatActive.chat_id,
        pertanyaan_id: dataidPertanyaan.value,
        penanya: datachatActive.owner,
        pertanyaan: chekedPertanyaan.value,
        cluster: data
      },{
        headers:{
          xth:userData.token
        }
      }).then(response => {
        console.log(response);
        var dataJawaban = response.data.data;
        chatInputMessage.value = dataJawaban.jawaban
        dataFile.value = dataJawaban.filename

      })
      .catch(function (error) {
        console.log(error);
      });
    }

    const refreshChat = () => {
      fetchChatAndContacts();
      openChat(activeChat.value.chat_id)
    }


    return {
      userData,
      chatUnmaped,
      avatarText,
      chekedPertanyaan,
      ambilPertanyaan,
      cluster,
      detailforSubmit,
      ambilJawaban,
      dataidPertanyaan,
      checkBoxPertanyaan,
      dataFile,
      disabledButton,
      refreshChat,
      // chatid,
      // pesan,
      // channel,
      // pengirim,
      // penerima,

      onClick,
      // Filters
      // formatDate,

      // useChat
      resolveAvatarBadgeVariant,

      // Chat & Contacts
      chatsContacts,
      contacts,

      // Single Chat
      refChatLogPS,
      activeChat,
      chatInputMessage,
      openChat,
      sendMessage,

      // Profile User Minimal Data
      profileUserDataMinimal,

      // User Profile Sidebar
      profileUserData,
      shallShowUserProfileSidebar,
      showUserProfileSidebar,

      // Active Chat Contact Details
      shallShowActiveChatContactSidebar,

      // UI
      perfectScrollbarSettings,

      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,
      resolveChat
    }
  },
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
