<template>
  <div
    class="user-profile-sidebar"
    :class="{'show': shallShowActiveChatContactSidebar}"
  >
    <!-- Header -->
    <header
      v-if="contact"
      class="user-profile-header"
    >
      <span class="close-icon">
        <feather-icon
          icon="XIcon"
          @click="$emit('update:shall-show-active-chat-contact-sidebar', false)"
        />
      </span>

      <div class="header-profile-sidebar">
        <div class="avatar box-shadow-1 avatar-xl avatar-border">
          <b-avatar
            size="70"
            :src="contact.avatar"
          />
          <span
            class="avatar-status-xl"
            :class="`avatar-status-${contact.status}`"
          />
        </div>
        <h4 class="chat-user-name">
          {{ contact.fullName }}
        </h4>
        <!-- <span class="align-middle">Mon - Fri 10AM - 8PM</span> -->
      </div>
    </header>

    <!-- User Details -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="user-profile-sidebar-area scroll-area"
    >

      <!-- About -->
      <h6 class="section-label mb-1">
        Pertanyaan
      </h6>
      <p>{{ dataPertanyaan }}</p>

      <!-- Personal Info -->
      <div class="personal-info">
        <h6 class="section-label mb-1 mt-3">
          Pilih Pertanyaan paling mirip?
        </h6>
        <div class="custom-control custom-radio" v-for="jwbn in clusterJawaban" :key="jwbn">
          <input type="radio" :id="jwbn.cluster" class="custom-control-input" name="optradio" :value="jwbn.cluster" v-model="jawabanAtasPertanyaan">
          <label class="custom-control-label" :for="jwbn.cluster">{{jwbn.cluster + " ("+jwbn.kemiripan+"%)"}} <a href="#modal-1" @click="showModal(jwbn.cluster)" data-toggle="modal" data-target="#modal-1"><i class="fa fa-circle-info"></i></a></label>
        </div>
        <div class="custom-control custom-radio">
         <a href="#">
          <input type="radio" id="customRadio2" name="customRadio" @click="checkData()" :checked="isChecked" class="custom-control-input">

          <label class="custom-control-label" for="customRadio2">Tidak ada</label>
          </a>
        </div>
      </div>
      <div style="margin-top:20px" class="d-flex justify-content-center">
      <button style="margin-right:5px" type="button" class="btn btn-outline-info" @click="lihatJawaban(jawabanAtasPertanyaan)">Lihat Jawaban</button>
      
      <button v-if="isChecked == true" style="margin-left:5px" type="button" class="btn btn-outline-success" @click="showModalTidakAda(); addmapping()">Submit</button>
      <button v-else style="margin-left:5px" type="button" class="btn btn-outline-success" @click="$emit('submitJawaban',jawabanAtasPertanyaan); $emit('closesidebar',false)">Submit</button>
      </div>
    </vue-perfect-scrollbar>

  <b-modal
      ref="my-modal"
      hide-footer
      title="Kelompok Pertanyaan:"
    >
      <div class="d-block">

        <ul class="list-group">
          <li class="list-group-item" v-for="pt in dataPertanyaancluster" :key="pt">{{pt.pertanyaan}}</li>
        </ul>
      </div>
  </b-modal>

  <b-modal
      ref="my-modalLihat"
      hide-footer
      title="Jawaban yang terkait:"
    >
      <div class="d-block">

        <ul class="list-group">
          <li class="list-group-item" v-for="jb in dataJawaban" :key="jb">{{jb.jawaban}}</li>
        </ul>
      </div>
  </b-modal>

  <b-modal
      ref="my-modaltidakada"
      hide-footer
      title="Form Pertanyaan:"
    >
      <div class="d-block">
        <div class="form-group">
          <label for="exampleInputEmail1">Nama Kelompok Pertanyaan</label>
          <input type="email" class="form-control" v-model="nama_cluster" id="exampleInputEmail1" aria-describedby="emailHelp">
        </div>
        <div class="form-group">
          <label for="exampleFormControlSelect1">Dipetakan Kepada</label>
          <select class="form-control" id="exampleFormControlSelect1" v-model="expert" >
            <option v-for="dt in listExpert" :key="dt">{{dt.nama}}</option>
          </select>
        </div>
      </div>

      <b-button variant="primary" class="float-right" @click="simpanPertanyaanBaru(detailSubmit.pertanyaan_id)">Simpan</b-button>
  </b-modal>

  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BAvatar,BButton } from 'bootstrap-vue'
import axios from 'axios'

export default {
  components: {
    // BSV
    BAvatar,
    BButton,

    // 3rd Party
    VuePerfectScrollbar,
  },
  mounted(){
    this.loadData()
  },
  data(){
    return{
      userData : JSON.parse(localStorage.getItem('userData')),
      dataPertanyaancluster:[],
      jawabanAtasPertanyaan:'',
      dataJawaban:[],
      isChecked: false,
      idpertanyaan:null,
      nama_cluster: null,
      expert: null,
      listExpert:[],
      url: localStorage.getItem('baseapi')
    }
  },
  props: {
    detailSubmit:{
      type: Object,
      required:true
    },
    shallShowActiveChatContactSidebar: {
      type: Boolean,
      required: true,
    },
    
    clusterJawaban:{
      type: Array,
      required:true,
    },
    dataPertanyaan:{
      type: String,
      required: true,
    },
    contact: {
      type: Object,
      required: true,
    },
  },
  methods:{
    loadData(){
      axios.get(this.url+'user?filter=hak_akses,=,expert',
      {
        headers:{
          xth: this.userData.token
        }
      }).then(response =>{
       
        this.listExpert = response.data.data
        console.log( this.listExpert);
      }).catch(function (error) {
        console.log(error);
      });
    },
    checkData() {
      if(this.isChecked == true){
        this.isChecked = false;
      }else{
         this.isChecked = true;
      }
      
      console.log(this.isChecked)
      
    },
    showModal(data) {
      this.$refs['my-modal'].show()
      axios.get(this.url+'pertanyaan_cluster?filter=cluster,=,'+data,
      {
        headers:{
          xth : this.userData.token
        }
      }).then(response => {
        console.log(response);
        this.dataPertanyaancluster = response.data.data
      })
      .catch(function (error) {
        console.log(error);
      });
      
    },
    addmapping(){
      var cluster = 'Tidak ada'
      axios.post(this.url+'pertanyaan_cluster',
      {
        chat_id: this.detailSubmit.chat_id,
        pertanyaan_id: this.detailSubmit.pertanyaan_id,
        penanya: this.detailSubmit.penanya,
        pertanyaan: this.detailSubmit.pertanyaan,
        cluster: cluster
      },{
        headers:{
          xth:this.userData.token
        }
      }).then(response => {
        console.log(response);
        console.log("addmapping")
      })
      .catch(function (error) {
        console.log(error);
        this.makeToast("error",response.data.message)
      });
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    showModalTidakAda() {
      this.$refs['my-modaltidakada'].show()
    },
    hideModalTidakAda() {
      this.$refs['my-modaltidakada'].hide()
    },
    lihatJawaban(data) {
      this.$refs['my-modalLihat'].show()
      console.log(data)
      axios.get(this.url+'cluster?filter=nama_cluster,=,'+data,
      {
        headers:{
          xth : this.userData.token
        }
      }).then(response => {
        console.log(response);
        this.dataJawaban = response.data.data
      })
      .catch(function (error) {
        console.log(error);
      });
      
    },
    simpanPertanyaanBaru(id_pertanyaan){
      //var detail = detailforSubmit.value
      console.log(id_pertanyaan)
      axios.post(this.url+'cluster',
      {
        nama_cluster: this.nama_cluster,
        pertanyaan_id: id_pertanyaan,
        expert: this.expert
      },{
        headers:{
          xth:this.userData.token
        }
      }).then(response => {
        console.log(response);
        this.makeToast("success",response.data.message)
        this.hideModalTidakAda()
        this.$emit('refreshChat')
        
      })
      .catch(function (error) {
        console.log(error);
        this.makeToast("error",response.data.message)
      });
    },
    makeToast(variant = null,pesan) {
      this.$bvToast.toast(pesan, {
        title: `${variant || 'default'}`,
        variant,
        solid: false,
      })
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    return {
      perfectScrollbarSettings,
    }
  },
}
</script>

<style>

</style>
